import React, { Component } from 'react'
import Immutable from 'immutable'
import Link from 'lucid-router/link'
const credentials = Immutable.fromJS(require('../data/credentials.json'))
import App from './App'
import styles from './Login.css'
import switchstyles from './Main.css'

export class Login extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      user: '',
      pw: '',
      errorMesg: ''
    }
  }

  checkEmail(e){
    const email = document.getElementById('email')
    const password = document.getElementById('password')
    if (email.value !== '' && password.value !== '') {
      const pass = credentials.some(elem => elem.toJS().email === email.value &&
                                   elem.toJS().password === password.value)

      if (!pass) {
        this.setState({
          errorMesg: 'Please enter correct Email and Password'
        })
        e.preventDefault();
      } else {
        sessionStorage.name = email.value;
        sessionStorage.pw = password.value;
      }
    } else {
      this.setState({
        errorMesg: 'Please enter both fields'
      });
      e.preventDefault();
    }
  }

  render(){
    return(
      <div className={styles.registerContainer}>
        <div className={styles.containerSmHeight}>
          <div className={styles.container}>
            <h4>Sign In</h4>
            <div className={styles.errorMesg}>{this.state.errorMesg}</div>
            <div className={styles.formGroup}>
              <label className={styles.pullLeft}>EMAIL</label>
              <div className={styles.borderNone}>
                <input type='text' id='email' placeholder='User Name' className={styles.formControl} required />
              </div>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.pullLeft}>PASSWORD</label>
              <div className={styles.borderNone}>
                <input type='password' id='password' className={styles.formControl} placeholder='Credentials' required />
              </div>
            </div>

            <Link to='main' className={styles.submit} onClick={this.checkEmail.bind(this)}>
              Login
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
