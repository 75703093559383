import React, { Component } from 'react'
import SwitchesView from './SwitchesView'
import Immutable from 'immutable'
const Company = Immutable.fromJS(require('../data/data.json'))
const feature_flags = Immutable.fromJS(require('../data/flag_data.json'))
import switchstyles from './Main.css'
import loginStyles from './Login.css'
import Stomp from '../node_modules/stompjs/lib/stomp.js'
import {getSwitches} from './switchFn'
import Link from 'lucid-router/link'
import styles from './Login.css'
const all_data = Immutable.fromJS(require('../data/all_data_modified.json'))

function compareFlags(allFlags, companyFlags) {
  // return feature flags that are not in company
  return allFlags.map(flag => {
    const flag_code = flag.flag_code
    const companyFlag = companyFlags.find(cFlag => flag_code === cFlag.flag_name)
    return Immutable.Map({
      flag_name: flag_code,
      desc: flag.flag_desc,
      status: companyFlag && companyFlag.status || 'OFF'
    })
  })
}

function findCompany(companies, searchTerm) {
  let matchCompanies = []

  companies.keySeq().toArray().map(key => {
    if (key.indexOf(searchTerm) !== -1) {
      matchCompanies.push(companies.get(key).toJS().name + ' :#:# ' + key)
    }
  })
  if (matchCompanies.length === 0) {
    const foundCompany = companies.filter(comp => {
      return comp.toJS().name.toUpperCase().indexOf(searchTerm.toUpperCase()) !== -1
    })
    for (let k in foundCompany.toJS()) {
      matchCompanies.push(foundCompany.toJS()[k].name + ' :#:# ' + k)
    }
  }
  return matchCompanies
}

function searchSwitches(id, companyName, flags, companies) {
  let allFlags = []
  companies.map((value, key) => {
    if (key === id) {
      allFlags = compareFlags(flags, companies.toJS()[key].feature_flags)
    }
  })
  return allFlags
}

export class Main extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      alert: false,
      companies: [],
      flags: [],
      clientSearchResultList: [],
      originalList: [],
      changeList: [],
      companyName: '',
      companyId: '',
      bannerMsg: '',
      changes: [],
      searchInput: '',
      errorMsg: '',
      displaySaveBtn: false,
      status: '',
      messageBody: ''
    }
  }
  startSearching(searchTerm) {
    this.getData(searchTerm)
  }
  getData(searchTerm) {
    let companies_data = null
    let c = []
    let f = []
    $.ajax({
      beforeSend: function (xhr) {
        xhr.setRequestHeader ('Authorization', 'Basic ' + btoa('admin@octanner.com' + ':' + 'i<3obert!'))
      },
      url: '/api/switches/companies?search=' + searchTerm,
      type: 'GET',
      success: data => {
        let result = []
        companies_data = Immutable.fromJS(data)
        companies_data.map((value,key) => {
          result.push(value.toJS().name + ' :#:# ' + key)
        })
        this.setState({companies: data, clientSearchResultList: Immutable.fromJS(result)})
      },
      error: err => {
        console.log('get error: ' + err)
        this.setState({
          errorMsg: 'Something went wrong, no companies listed'
        })
      }
    })
    $.ajax({
      beforeSend: function (xhr) {
        xhr.setRequestHeader ('Authorization', 'Basic ' + btoa('admin@octanner.com' + ':' + 'i<3obert!'))
      },
      // url: 'http://localhost:8083/api/switches/feature-flags',
      url: '/api/switches/feature-flags',
      type: 'GET',
      success: data => {
        this.setState({flags: data})
      },
      error: err => {
        console.log('get error: ' + err)
        this.setState({
          errorMsg: 'Something went wrong, no companies listed'
        })
      }
    })
  }
  showAlert(e){
    this.setState({
      alert: true
    })
  }
  closeBanner(){
    document.getElementById('overlay').style.visibility = 'hidden'
    this.setState({ alert: false })
  }
  searchCompany(searchTerm) {
    const matchCompanies = findCompany(this.state.companies, searchTerm)
    if (matchCompanies.length === 0) {
      this.setState({
        clientSearchResultList: [],
        errorMsg: 'Search result not found'
      })
    }
    else {
      this.setState({
        clientSearchResultList: matchCompanies,
        displaySaveBtn: true
      })
    }
  }
  onChange(e) {
    this.setState({searchInput: e.target.value,
                  errorMsg:''})
    if (e.target.value.length >= 3) {
      this.startSearching(e.target.value)
    } else {
      this.setState({clientSearchResultList: []})
    }
  }
  showSwitches(companyInfo) {
    const arrayInfo = companyInfo.split(' :#:# ')
    const companyName = arrayInfo[0]
    const companyId = arrayInfo[1]
    const allFlags = searchSwitches(companyId, companyName, this.state.flags, Immutable.fromJS(this.state.companies))
    this.setState({changeList: allFlags,
                   companyName: companyName,
                   companyId: companyId,
                   originalList: allFlags,
                   clientSearchResultList: []
    })
    document.getElementById('searchbox').value = ''
  }
  clearFields() {
    document.getElementById('searchbox').value=''
    this.setState({changeList: [],
                    companyName: '',
                    companyId: '',
                    changes: [],
                    alert: false,
                    clientSearchResultList: [],
                    errorMsg: '',
                    originalList: []
                  })
  }
  changeStatus(clickedKey) {
    let t = this.state.changeList
    const i = t.findIndex(clicked => clicked.get('flag_name') === clickedKey)
    t = Immutable.fromJS(t).update(i, clicked => clicked.update('status', status => status.toUpperCase() === 'ON' ? 'OFF' : 'ON'))

    this.setState({
      changeList: t
    })
  }
  diffChanges() {
    const diffList = this.state.changeList.filter(item => {
      const fName = item.get('flag_name')
      const originalItem = this.state.originalList.find(oItem => oItem.get('flag_name') === fName)
      return originalItem && originalItem.get('status') !== item.get('status')
    })
    const id = this.state.companyId
    const urls = '/api/switches/' + id + '/update-switches-info'
    // const urls = 'http://localhost:8083/api/switches' + id + '/update-switches-info'
    const changed = this.state.changeList.toJS()
    $.ajax({
      beforeSend: function (xhr) {
        xhr.setRequestHeader ('Authorization', 'Basic ' + btoa('admin@octanner.com' + ':' + 'i<3obert!'))
      },
      url: urls,
      type: 'PUT',
      data: JSON.stringify(changed, null, 2),
      contentType: 'application/json',
      crossDomain: true,
      success: function(data) {
        console.log(data)
      },
      error: function(err) {
        console.log('error: ' + JSON.stringify(err, null, 2))
      }
    })
    this.setState({changes: diffList, alert: true})
    document.getElementById('overlay').style.visibility = 'visible'
  }
  render(){
    if (typeof sessionStorage === 'undefined' ||
        (sessionStorage.name !== 'admin@octanner.com' &&
        sessionStorage.name !== 'user@octanner.com')) {
      return (
        <div className={styles.notLoggedIn}>
          Please click
          <Link to='login' className={styles.submit}>
            here
          </Link>
          to go back to login page
        </div>
      )
    }
    const allSwitches = getSwitches(this.state.companies)
    return(
      <div className={switchstyles.contentStyle}>
        <div className={switchstyles.overlay} id='overlay'></div>
        <h3>Client Lookup</h3>
        <div className={switchstyles.inputGroup} id='searchBar'>
          <label>Enter STP number or Client Name</label>
          <br />
          <input id='searchbox' type='text' className={switchstyles.searchBox} onChange={e => this.onChange(e)}/>
          <div id='results'></div>
          {(this.state.clientSearchResultList.size > 0) ?
          <div className={switchstyles.sValue}>
            <ul>
              {
                Immutable.fromJS(this.state.clientSearchResultList).map((value) => <li key={value}><a href='#' key={value}
                    className='btn1' onClick={() => this.showSwitches(value)}>{value}</a></li>)
              }
            </ul>
          </div>:
            <div>
              {(this.state.errorMsg === '')?<div></div>:<div>{this.state.errorMsg}</div>}
            </div>
          }
          </div>
          <div className={switchstyles.searchedCompany}>{this.state.companyName} {this.state.companyId}</div>
          {
            this.state.changeList.map((value, key)=>
            {
              const name = value.get('flag_name')
              const desc = value.get('desc').toUpperCase()
              const status = value.get('status')
              return <SwitchesView
                key={name}
                changeStatus={this.changeStatus.bind(this)}
                switchKey={name}
                switchDesc={desc}
                switchStatus={status}
              />
            })
          }
          <div>
          {
            this.state.originalList.length !== 0
              ? <button type='button' className={switchstyles.saveBtn} onClick={() => this.diffChanges()}>SAVE</button>
              : <div></div>
          }
          <button type='button' className={switchstyles.cancelBtn} onClick={() => this.clearFields()}>CANCEL</button>
          </div>
        <br/><br/>
        {
          this.state.alert
            ? this.state.changes.length === 0
              ? (
                <div className={switchstyles.alertBannerNo} role='alert'>
                  <button onClick={() => this.closeBanner()} type='button' className='close'
                    data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>
                  You did not make any changes
                </div>
              ) : (
                <div className={switchstyles.alertBanner} role='alert'>
                  <button onClick={() => this.closeBanner()} type='button' className='close'
                    data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span>
                  </button>
                  <br/>You have changed the following feature flags:<br />
                  <ul>
                  {
                    this.state.changes.map((value, key)=>
                    {
                      const name = value.get('desc').toUpperCase()
                      const status = value.get('status')
                      return (
                        <li key={key}>
                          <strong>{name} </strong>is now<strong> {status}</strong>
                        </li>
                      )
                    })
                  }
                  </ul>
              </div>
            ) : <div></div>
        }
        </div>
    )
  }
}
