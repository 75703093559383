import React from 'react'
import { Login } from './Login'
import { Main } from './Main'

const viewFor = location => {
  if (!location) return <Login />
  switch (location.name){
    case 'login':         return <Login />
    case 'main':      return <Main />
  }
}

export const App = ({location}) =>
  <div>
    <section>
      {viewFor(location)}
    </section>
  </div>
