import React from 'react'
import ReactDom from 'react-dom'
import { App } from './source/App'
import * as router from 'lucid-router'
import routes from './source/routes'

router.addRoutes(routes)

router.register(location => render(location))

render(router.getLocation())

function render(location) {
  ReactDom.render(<App location={location} />, document.getElementById('content'))
}
