export function getSwitches(companies){

  let allSwitches = []
  // companies.forEach(function(company) {
  for (let c in companies) {
    for(let key in companies[c]) {
      if(key === 'feature_flags'){
        const feature_flags = companies[c][key]
        feature_flags.forEach(function(companySwitch){
          let switchKey, switchValue, switchDesc
          for(let skey in companySwitch)
          {
            if(skey === 'flag_name') {
              switchKey = companySwitch[skey]
            }
            else if (skey === 'status') {
              switchValue = companySwitch[skey]
            } else {
              switchDesc = companySwitch[skey]
            }
          }
          let s = {}
          s.switchName = switchKey
          s.switchStatus = switchValue
          s.switchDesc = switchDesc
          allSwitches.push(s)
        })
      }
    }
  }
  return allSwitches
}
