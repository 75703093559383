import React from 'react'
import switchstyles from './Main.css'

export default class SwitchesView extends React.Component{
  constructor(props){
    super(props)
  }
  onClick() {
    this.props.changeStatus(this.props.switchKey)
  }
  render() {
    //deConstructing
    let {switchKey, switchDesc, switchStatus} = this.props
    switchStatus = (switchStatus.toUpperCase() === 'ON')? true: false

    return (
      <div>
        <table className={switchstyles.table}>
          <tbody>
            <tr>
              <td>
                <span className={switchstyles.switchName} id='switchName'>{switchDesc}</span>
              </td>
              <td className="text-right">

                <span>
                  <button onClick={() => this.onClick()} type='button'
                  className={(switchStatus) ? switchstyles.switchStatusBtn : switchstyles.switchStatusBtnOff} >
                  {switchStatus ? 'ON' : 'OFF'}
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
